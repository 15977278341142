.logPanel {
  position: fixed; /* Panelin ekranın sağ alt köşesinde sabit kalmasını sağlar */
  bottom: 20px; /* Ekranın altından 20px yukarıda */
  right: 20px; /* Ekranın sağından 20px içeride */
  width: 300px; /* Panel genişliği */
  max-height: 400px; /* Panelin maksimum yüksekliği */
  background-color: black; /* Arka plan rengi */
  color: white; /* Yazı rengi */
  border-radius: 8px; /* Köşe yuvarlama */
  padding: 10px; /* İçerik için boşluk */
  overflow-y: auto; /* İçerik taşarsa kaydırma çubuğu ekler */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Gölgelendirme efekti */
}

.logTitle {
  margin: 0; /* Başlık için üst ve alt boşluğu sıfırla */
  font-size: 18px; /* Başlık font boyutu */
}

.logContent {
  margin-top: 10px; /* Başlık ile içerik arasında boşluk */
}

.logEntryArea {
  max-height: 250px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2); /* Log girişleri arasında çizgi */
  padding: 5px 0; /* Log girişleri için üst ve alt boşluk */
  overflow-y: auto;
}

.logEntryArea p {
  border-bottom: 1px solid white;
  border-bottom-style: dotted;
}
