/* Navbar.css */
.navbar {
  width: 100%;
  background-color: #333;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 60px;
  width: 100%;
  max-width: 1200px;
}

.navbar-logo {
  display: flex;
  align-items: center;
}

.navbar-logo a {
  color: #fff;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
}

.menu-icon {
  display: none;
  cursor: pointer;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
}

.nav-links {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  display: flex;
  align-items: center;
}

.nav-item {
  height: 60px;
}

/* Hamburger icon (mobil) */
.fa-bars,
.fa-times {
  color: #fff;
  font-size: 1.8rem;
}

/* Mobil stili */
@media screen and (max-width: 768px) {
  .navbar-container {
    justify-content: space-between;
  }

  .menu-icon {
    display: block;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: -100%;
    width: 100%;
    height: 90vh;
    background-color: #333;
    opacity: 0.9;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    left: 0;
  }

  .nav-links {
    padding: 2rem;
    width: 100%;
    text-align: center;
  }
}
