* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
}

a {
  cursor: pointer;
}

.allPage {
  height: 100%;
}

.Page {
  height: 100%;
  display: flex;
}

.mainContainer {
  width: 120px;
  height: 100vh;
  overflow-y: scroll;
}

.cookieContainer {
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}

.cookieContainer .cookieTitle {
  word-break: break-all;
  text-align: center;
}

.Page {
  height: 100%;
  position: relative;
}

.panel,
.entry-panel {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: auto;
  background-color: black;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 25px;
  text-align: left;
  border-radius: 25px;
}

.panel {
  height: 500px;

  overflow-y: scroll;
}

.model-container,
.entry-panel {
  position: fixed; /* Ekranda sabit kalması için */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 70vh;
  display: flex;
  background-color: rgba(0, 0, 0); /* Yarı saydam arkaplan */
  z-index: 1000; /* Öne çıkması için */
  overflow: auto;
  padding: 30px 10px;
  border-radius: 25px;
}

.model-container button {
  width: 50px;
  margin-bottom: 20px;
}

.modal-content {
  position: relative;
  width: 300px;
  height: 100%; /* Ekranın %70 yüksekliği */
  padding: 20px;
  background-color: white;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: auto; /* Taşan içerik için scroll aktif */
}

.entry-text,
.entry-panel h1 {
  color: white;
}

.entry-panel button,
.panel button {
  width: 50px;
  height: 25px;
  text-align: center;
}

.cookieContainer h1,
h2,
h3,
h4,
h5,
p {
  color: white;
}

.cookieContainer:hover {
  opacity: 0.8;
  cursor: pointer;
}

.title {
  text-align: center;
}

.circle {
  width: 15px;
  height: 15px;
  position: absolute;
  border-radius: 50%;
  background-color: green;
  pointer-events: none; /* Dairelerin fareyle etkileşime girmesini engeller */
}

/* Renk geçişi için sınıflar */
.circle.green {
  background-color: green;
}
.circle.yellow {
  background-color: yellow;
}
.circle.orange {
  background-color: orange;
}
.circle.red {
  background-color: red;
}

/* Webkit tarayıcılar (Chrome, Safari) için */
::-webkit-scrollbar {
  width: 12px; /* Scroll bar genişliği */
}

::-webkit-scrollbar-track {
  background: #ffffff; /* Arkaplan rengi */
  /* Yuvarlak köşeler */
}

::-webkit-scrollbar-thumb {
  background-color: #000000; /* Scroll bar rengi */
  /* Scroll bar yuvarlak köşeler */
  border: 3px solid #f0f0f0; /* İç kısmındaki boşluk */
}

/* Scroll bar hover olduğunda rengi değiştirebilirsin */

.product-list {
  display: flex;
  justify-content: center;
  padding: 20px;
  height: 1020px;
  flex-wrap: wrap;
  gap: 30px;
}

.product-item {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
  width: 200px;
  height: 350px;
}

.product-image {
  width: 100%;
  height: 150px;
}

.product-name {
  font-size: 18px;
  margin: 10px 0;
  color: black;
}

.product-price {
  color: #888;
  margin-bottom: 10px;
}

.credit {
  color: white !important;
}

.add-to-cart {
  background-color: black;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.add-to-cart:hover {
  background-color: #45a049;
}

.allPage {
  width: 100%;
}

.reklam {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.ads-panel {
  width: 400px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: white;
  background-color: black;
  border-radius: 15px;
  animation: flashing 2s infinite, colorShift 4s infinite;
}

/* Yanıp sönme efekti */
@keyframes flashing {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

/* Köşelerin renkten renge değişmesi */
@keyframes colorShift {
  0% {
    border: 5px solid red;
  }
  25% {
    border: 5px solid yellow;
  }
  50% {
    border: 5px solid green;
  }
  75% {
    border: 5px solid blue;
  }
  100% {
    border: 5px solid red;
  }
}

.screen-warning {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 1000;
}

.warning-text {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

/* Desktop ekranları için ana içerik */
.main-content {
  padding: 20px;
  text-align: center;
}

/* 900px altındaki cihazlarda site içeriğini gizle */
@media only screen and (max-width: 900px) {
  .main-content {
    display: none;
  }
}

.cookie-banner {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #333;
  color: white;
  padding: 15px;
  text-align: left;
}

.cookie-banner p {
  margin: 0;
  font-size: 14px;
}

.cookie-banner__button {
  background-color: #fff;
  border: none;
  color: #333;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  margin-top: 3px;
}

.cookie-banner__button:hover {
  background-color: #ddd;
}

.banner-buttons {
  display: flex;
  gap: 10px;
}
